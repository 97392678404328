@font-face {
    font-family: 'Space Grotesk Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Space Grotesk Regular'), url('/assets/fonts/SpaceGrotesk-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk Light';
    font-style: normal;
    font-weight: normal;
    src: local('Space Grotesk Light'), url('/assets/fonts/SpaceGrotesk-Light.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Space Grotesk Medium'), url('/assets/fonts/SpaceGrotesk-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Space Grotesk SemiBold'), url('/assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Space Grotesk Bold'), url('/assets/fonts/SpaceGrotesk-Bold.woff') format('woff');
}