header {
    background: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    > div {
        max-width: rem-calc(1400);
        margin: auto;
        display: grid;
        grid-template-columns: rem-calc(173) auto rem-calc(173);
        align-items: center;
        padding: rem-calc(34) rem-calc(20);
        transition: all linear .3s;
        @media screen and (max-width: 1024px) {
            grid-template-columns: rem-calc(173) auto;
        }
        > button {
            font-family: 'Space Grotesk Bold';
            &:first-of-type {
                justify-self: start;
            }
            &:last-of-type {
                justify-self: end;
                @media screen and (max-width: 1024px) {
                    display: none;
                    visibility: hidden;
                }
            }
        }
        #nav {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 1024px) {
                display: none;
                visibility: hidden;
            }
            a {
                font-family: 'Space Grotesk Bold';
                font-size: rem-calc(24);
                color: $onyx;
                position: relative;
                cursor: pointer;
                &::after {
                    content: '';
                    transition: all linear .3s;
                    opacity: 0;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: rem-calc(4);
                    height: rem-calc(4);
                    border-radius: 100%;
                    background: $onyx;
                }&:active, &:visited {
                    color: $onyx;
                }
                &:hover, &.active {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        ul, li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        ul {
            display: grid;
            grid-template-columns: repeat(6, auto);
            gap: rem-calc(50);
        }
    }
    &.slim {
        > div {
            grid-template-columns: rem-calc(80) auto rem-calc(173);
            padding: rem-calc(14) rem-calc(20);
            @media screen and (max-width: 1024px) {
                grid-template-columns: rem-calc(173) auto;
            }
            nav a {
                font-size: rem-calc(20);
            }
        }
    }
    button + div {
        display: none;
        visibility: hidden;
        @media screen and (max-width: 1024px) {
            display: block;
            visibility: visible;
            justify-self: end;
        }
    }
}

.bm-item-list {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    li:not(:last-of-type) {
        margin-bottom: 20px;
    }
    a {
        font-family: 'Space Grotesk Bold';
        font-size: rem-calc(24);
        color: $white;
        position: relative;
        width: 100%;
        cursor: pointer;
        &::after {
            content: '';
            transition: all linear .3s;
            opacity: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: rem-calc(4);
            height: rem-calc(4);
            border-radius: 100%;
            background: $white;
        }
        &:active, &:visited {
            color: $white;
        }
        &:hover, &.active {
            &::after {
                opacity: 1;
            }
        }
    }
    ul, li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

}

/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: $irisBlue;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: $onyx;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: $onyx;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}