.button {
    padding: rem-calc(14) rem-calc(25);
    font-family: 'Space Grotesk Bold';
    font-size: rem-calc(20);
    font-weight: normal;
    transform: perspective(1px) translateZ(0);
    width: fit-content;
    appearance: none;
    border: 0 none;
    cursor: pointer;
    &-arrow {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: rem-calc(8);
    }
    span {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            right: 100%;
            bottom: 0;
            height: 1px;
            transition: ease-out right 0.3s;
        }
    }
    &-dark {
        background: $onyx;
        color: $white;
        span {
            &::before {
                background: $white;
            }
        }
    }
    &-dark-border {
        background: $onyx;
        color: $white;
        border: 2px solid $white;
        span {
            &::before {
                background: $white;
            }
        }
    }
    &-light {
        background: $white;
        color: $onyx;
        span {
            &::before {
                background: $onyx;
            }
        }
    }
    &-grey {
        background: $desertStorm;
        color: $onyx;
        span {
            &::before {
                background: $onyx;
            }
        }
    }
    svg {
        transition: ease-out transform .1s;
    }
    &:hover {
        span {
            &::before {
                right: 0;
            }
        }
        > svg {
            transform: translateX(4px) rotate(-45deg);
        }
    }
    &::after {
        display: none;
        visibility: hidden;
    }
}

.button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: clamp(1.25rem, -0.592rem + 5.519vw, 4.375rem);
    margin-bottom: clamp(2.5rem, -0.079rem + 7.726vw, 6.875rem);
}

.close-btn {
    background: transparent;
    border: 0 none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem-calc(8);
    font-family: 'Space Grotesk Medium';
    cursor: pointer;
    padding: rem-calc(20);
    position: absolute;
    top: 0;
    right: 0;
    color: $onyx;
    svg {
        transition: all linear .3s;
    }
    &:hover {
        svg {
            transform: rotate(90deg);
        }
    }
}