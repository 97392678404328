*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    font-family: 'Space Grotesk Regular';
    font-size: rem-calc(16);
    background-color: $desertStorm;
}

.hero-wrapper {
    margin-top: clamp(6.125rem, -1.6639rem + 14.3488vw, 7.5rem);
    padding: 0 rem-calc(20);
    padding-top: clamp(3.125rem, -1.6639rem + 14.3488vw, 11.25rem);
    padding-bottom: clamp(3.125rem, -1.6639rem + 14.3488vw, 11.25rem);
    background-color: $irisBlue;
}

.hero-area {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: center;
    align-items: center;
    max-width: rem-calc(1440);
    margin: auto;
    @media screen and (max-width: 700px) {
        grid-template-columns: 100%;
        .hero-image {
            padding-top: rem-calc(40);
        }
    }
    .hero-text {
        p, h1 {
            margin: 0;
        }
        p {
            font-size: rem-calc(20);
        }
        h1 {
            margin: rem-calc(15) 0;
        }
    }
}


a {
    text-decoration: none;
    transition: all linear .3s;
}

img, svg {
    max-width: 100%;
    height: auto;
    display: block;
}

.wrapper {
    max-width: rem-calc(1440);
    margin: auto;
    padding: 0 rem-calc(20);
}

.inner-wrapper {
    max-width: rem-calc(1200);
    position: relative;
}

.white-bg {
    background: $white;
    padding: clamp(3.125rem, 0.915rem + 6.623vw, 6.875rem) rem-calc(10) clamp(3.125rem, 0.915rem + 6.623vw, 6.875rem);
    h2 {
        margin-top: 0;
    }
}

.inner-padding {
    padding: clamp(3.125rem, 0.915rem + 6.623vw, 6.875rem) rem-calc(30) clamp(3.125rem, 0.915rem + 6.623vw, 6.875rem);
    h2 {
        margin-top: 0;
    }
}

.services {
    display: grid;
    gap: rem-calc(48);
    grid-template-columns: repeat(3, calc((100% - 96px) / 3));
    margin-top: -13%;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 100%;
        margin-top: rem-calc(30);
    }
    .service {
        padding: clamp(1.125rem, 0.02rem + 3.311vw, 3rem);
        background: $white;
        color: $onyx;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
        display: grid;
        grid-template-columns: subgrid;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem-calc(17);
            border: 1px solid $whiteLilac;
            margin-bottom: rem-calc(42);
            width: fit-content;
            height: fit-content;
        }
        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem-calc(34);
            padding: rem-calc(17);
            background: $desertStorm;
            width: fit-content;
            height: fit-content;
        }
        h4, p {
            margin: 0;
        }
        h4 {
            margin-bottom: rem-calc(14);
        }
        &:hover, &.active {
            background: $onyx;
            color: $white;
            p {
                color: $white;
            }
            .icon {
                border-color: $white;
                svg path {
                    fill: $white;
                }
            }
            .arrow {
                background: $charcoal;
                svg {
                    transform: rotate(-45deg);
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
}

.work {
    background: $white;
    color: $onyx;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: auto rem-calc(700);
    &:not(:last-child) {
        margin-bottom: rem-calc(64);
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: 100%;
    }
    .work-content {
        display: grid;
        padding: clamp(1.5rem, 0.026rem + 4.415vw, 4rem) 0 clamp(1.5rem, 0.026rem + 4.415vw, 4rem) clamp(1.5rem, 0.026rem + 4.415vw, 4rem);
        z-index: 10;
        @media screen and (max-width: 1024px) {
            padding: clamp(1.5rem, 0.026rem + 4.415vw, 4rem);
            gap: rem-calc(30);
        }
        h3, p {
            margin: 0;
        }
        h3 {
            margin-top: clamp(1.25rem, 0.219rem + 3.091vw, 3rem);
            margin-bottom: rem-calc(20)
        }
        .button {
            align-self: end;
        }
    }
    .work-img {
        position: relative;
        .fade {
            background: linear-gradient(90deg, #FFFFFF 6.25%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
        }
        img {
            filter: grayscale(100%);
        }
    }
}

// Experience
.experience-years {
    .swiper-slide {
        background: $desertStorm;
        padding: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
        height: unset;
        .experience {
            display: grid;
            gap: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
            height: 100%;
            justify-content: start;
            .content {
                min-height: rem-calc(185);
            }
        }
        p {
            margin-top: 0;
        }
        .xsmall {
            margin: 0;
        }
        .job-info {
            align-self: end;
            p:not(.xsmall) {
                margin: 0;
                margin-top: rem-calc(5);
                font-family: 'Space Grotesk SemiBold';
            }
        }
    }
}

.swiper-header {
    position: relative;
}

.swiper-buttons {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: repeat(2, rem-calc(23));
    gap: rem-calc(23);
    > div {
        width: rem-calc(32);
        height: rem-calc(32);
        cursor: pointer;
        &.previous-btn {
            transform: rotate(180deg);
        }
        &.swiper-button-disabled {
            svg path {
                fill: $paleSlate;
            }
        }
    }
}

// Skills
.skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
    @media screen and (max-width: 1024px) {
        grid-template-columns: 100%;
    }
    > div {
        background: $white;
        padding: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
        height: rem-calc(447);
        @media screen and (max-width: 1024px) {
            height: unset;
        }
        p:first-of-type {
            font-family: 'Space Grotesk SemiBold';
        }
    }
}

// Education
.education-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
    @media screen and (max-width: 1024px) {
        grid-template-columns: 100%;
    }
    .education {
        background: $desertStorm;
        padding: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
        @media screen and (max-width: 1024px) {
            height: unset;
        }
        p {
            margin: 0;
            &:first-of-type {
                font-family: 'Space Grotesk SemiBold';
            }
            &:not(:last-of-type) {
                margin-bottom: rem-calc(5);
            }
        }
        a {
            margin: 0;
            margin-bottom: rem-calc(5);
            color: $onyx;
            text-decoration: underline;
            transition: all linear .3s;
            &:hover {
                color: $cardinal;
            }
        }
    }
}

// Contact
.contact {
    margin: auto;
    background: $white;
    padding: clamp(1.75rem, 1.013rem + 2.208vw, 3rem);
    h2 {
        margin-top: 0;
    }
    .form-item {
        margin: rem-calc(24) 0;
    }
    form {
        max-width: rem-calc(576);
        margin: auto;
    }
    label {
        display: block;
        font-size: rem-calc(14);
        margin-bottom: rem-calc(5);
        text-transform: uppercase;
    }
    input, textarea {
        width: 100%;
        padding: rem-calc(12);
        background: $wildSand;
        border: 0 none;
        font-size: rem-calc(16);
    }
    input[type='submit'] {
        background: $onyx;
        color: $white;
        font-size: rem-calc(20);
        cursor: pointer;
        transition: all linear .3s;
        &:hover {
            background: $irisBlue;
        } 
    }
    h4 {
        text-align: center;
    }
}

.contact-page {
    .contact {
        padding-top: rem-calc(140);
    }
}

.ReactModal__Overlay {
    z-index: 9999;
    .contact {
        max-width: rem-calc(576);
        width: 100%;
    }
}