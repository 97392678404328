footer {
    background: $onyx;
    color: $white;
    padding: 0 rem-calc(30);
    .footer-wrapper {
        max-width: rem-calc(1200);
        margin: auto;
        display: grid;
        grid-template-columns: 60% 50%;
        @media screen and (max-width: 1024px) {
            grid-template-columns: 100%;
        }
        &:first-of-type {
            padding-top: clamp(1.875rem, -3.6507rem + 16.5563vw, 6.25rem);
        }
        &:nth-of-type(2) {
            padding-top: rem-calc(50);
            padding-bottom: clamp(1.875rem, -1.8088rem + 11.0375vw, 4.125rem);
            display: flex;
            flex-direction: column;
            gap: rem-calc(10);
            a {
                color: $white;
                font-size: rem-calc(20);
                &:hover {
                    opacity: .8;
                }
            }
        }
        &:last-of-type {
            padding-top: clamp(1.875rem, 1.1382rem + 2.2075vw, 3.125rem);
            padding-bottom: clamp(1.875rem, 1.1382rem + 2.2075vw, 3.125rem);
            border-top: 1px solid rgba(255, 255, 255, .1);
        }
    }
}